import Link from 'next/link';

import Footer from '@/components/Landing/LandingFooter';
import LandingHelp from '@/components/Landing/LandingHelp';

import { btnStyles } from '@/styles/buttons.css';

import { routes } from '@/utils/routes';

import {
  containerClass,
  contentClass,
  helpContainerClass,
  matchaGreenClass,
  titleClass,
} from '../500/index.css';

const Error404 = () => {
  return (
    <div className={containerClass}>
      <div className={contentClass}>
        <h1 className={titleClass}>
          <span className={matchaGreenClass}>404</span>
          <br />
          Page not found
        </h1>
        <Link
          href={{
            pathname: routes.TOKEN_PROFILE,
            query: {
              chainId: 'ethereum',
              contractAddresses: ['eth'],
            },
          }}
        >
          <div className={btnStyles.PRIMARY_MD}>Back to Ethereum token page</div>
        </Link>
      </div>
      <div className={helpContainerClass}>
        <LandingHelp />
      </div>
      <Footer />
    </div>
  );
};

export default Error404;
