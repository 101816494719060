import { NextSeo } from 'next-seo';

import Header from '@/components/Header';
import Error404 from '@/components/pages/Error/404';

const Custom404Page = () => {
  return (
    <>
      <NextSeo title="Page not found | matcha" noindex nofollow />
      <Header />
      <Error404 />
    </>
  );
};

export default Custom404Page;
